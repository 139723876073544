import * as Sentry from '@sentry/browser'

const options = {
    dsn: document.documentElement.dataset.sentryDsn || '',
    allowUrls: window.location.hostname,
    beforeSend(event, hint) {
        const seznamBrowser = /SznProhlizec/.test(navigator.userAgent)
        const errorMessage = hint?.originalException?.message

        if (seznamBrowser && errorMessage) {
            if (errorMessage.match(/is not defined/i)) {
                return null
            }

            if (errorMessage.match(/formAvailible/)) {
                return null
            }
        }

        return event
    }
}

if (document.documentElement.dataset.sentryEnvironment) {
    options.environment = document.documentElement.dataset.sentryEnvironment
}

if (options.dsn) {
    Sentry.init(options)
    Sentry.configureScope(scope => {
        scope.setTag('logger', 'js')
        scope.setTag('module', 'frontend')
    })
}