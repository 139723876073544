// set basepath of project for webpack
__webpack_public_path__ = document.querySelector('html').dataset.basepath || '/assets/frontend/js/';
import { sentryConfig } from './core/config';

import 'console-polyfill';
import './polyfills/SVGElement.classList';
import './polyfills/SVGElement.dataset';
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

try {
    Object.values({}) // polyfill pro facebook
} catch (e) {}

import './plugins/nette.ajax';
import './plugins/nette.ajax.history';
import './plugins/giant.show-if';

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';
import './nette/NetteEvents';
import './nette/NetteLoading';

import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

if ($.nette) {
    $.nette.init();
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver';
new TouchMouseInputResolver();

import { loadComponents, removeComponents } from './core/componentUtils'
loadComponents()


/**
 * lazy loading
 */
window.lazySizesConfig.lazyClass = 'is-lazy';
window.lazySizesConfig.loadingClass = 'is-lazy-loading';
window.lazySizesConfig.loadedClass = 'is-lazy-loaded';
import 'lazysizes';
